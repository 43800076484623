import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import CTA from "../../components/cta";
import Layout from "../../components/layout";
import Quote from "../../components/quote";

import patternHex from "../../images/backrounds/pattern-hex-white.jpg";
import ZendeskLunchLearnWebinarReplay from "../../docs/Zendesk Lunch & Learn October 19, 2021 Webinar Replay.pdf";

const Hero = () => (
  <div
    className="hero mt-0"
    style={{
      backgroundImage: `url(${patternHex})`,
    }}
  >
    <div className="hero-title bg-dark-transparent">
      <div className="hero-image">
        <StaticImage
          src="../images/clients/zendesk.png"
          alt="Zendesk Implementation Premier Partner"
          layout="constrained"
          style={{ maxWidth: "200px"}}
        />
      </div>
      <h1 className="text-white">
        Zendesk Lunch & Learn Webinar Replay
      </h1>
      <h3 className="mt-0">
        October 19, 2021
      </h3>
      <p className="text-white">
        Learn about Zendesk products and watch the replay for a live Q&A and demo.
      </p>
      <AnchorLink to="#watch-replay" className="btn btn-primary">Watch the Replay</AnchorLink>
    </div>
  </div>
);

const Video = () => (
  <section className="pt-6" id="watch-replay">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe title="Zendesk Lunch & Learn | October 19, 2021 | Webinar Replay" className="embed-responsive-item" src="https://www.youtube.com/embed/KGEAQrYs37Q?rel=0" allowFullScreen></iframe>
          </div>
        </div>
        <div className="col-12 text-center pt-4">
          <a className="btn btn-primary-light" target="_blank" rel="noreferrer" href={ZendeskLunchLearnWebinarReplay}>Download Transcript</a>
        </div>
      </div>
    </div>
  </section>
);

const Trends = () => (
  <section className="pt-6">
    <div className="container">
      <div className="row text-center">
        <div className="col">
          <h3>So... Would You Like To Have More Customers?<br/>Who Are Happier? </h3>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-3">
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases customer satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">25%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases agent satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">30%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">Customers will spend more to buy from a company that offers good customer service by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">75%*</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col">
          <p>* CX Trends Report 2021</p>
        </div>
      </div>
    </div>
  </section>
);

const Quotes = () => (
  <section className="py-6">
    <div className="container">
      <div className="row text-center">
        <div className="col">
          <h2>Here’s What A Few Users Have Said<br/>About Their Zendesk Experiences:</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;It helps our teams maintain the personalization that our clients need while quickly solving their questions or help requests. The best part?
            Our customers feel genuinely supported and heard.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;We used Zendesk at 3 of my last 4 jobs. The last 2 at my suggestion. We handle a diverse amount of different customer issues and
            it's a great system to manage and track all the different interactions our customers are having as well as the agents that are handling them.&quot;"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;I can't stress enough
            how easy Zendesk makes it to pass tickets back and forth between people and teams.
            My team gets a constant flow of tickets and we are able to keep them organized by time, urgency, and topic.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;Zendesk really is the best CRM I have ever used, and I was in customer service for 10 years. By far and away
            the most intuitive, simple, and clean user experience you will have for a consumer-facing CR interface.&quot;
            "
          />
        </div>
      </div>
    </div>
  </section>
);
const Footer = () => (
  <section
    className="d-flex bg-pattern-trihex bg-white py-6"
    style={{
      backgroundSize: "150px",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <CTA href="/contact-us/" text="Get Started" className="btn btn-primary"/>
        </div>
      </div>
    </div>
  </section>
);

const Index = () => (
  <Layout>
    <Hero />
    <Video/>
    <Trends />
    <Quotes />
    <Footer />
  </Layout>
);

export default Index;
